import {rentalsService} from "../../apis/rentals.service";

const state = {
    cars: [],
    selectedCar: {},
    controls: [],
    configs: {},
    cost: {}
}

const getters = {
    'GET_CARS' (state) {
        return state.cars;
    },
    'GET_SELECTED_CAR' (state) {
        return state.selectedCar;
    },
    'GET_RENTAL_CONTROLS' (state) {
        return state.controls;
    },
    'GET_ARRIVAL_RENTAL_CONTROLS' (state) {
        console.log('state controls',state.controls);
        return state.controls.filter(el => el.use_for_arrival === "yes");
    },
    'GET_DEPARTURE_RENTAL_CONTROLS' (state) {
        return state.controls.filter(el => el.use_for_departure === "yes");
    },
    'GET_RENTAL_SETUPS' (state) {
        return state.configs;
    },
    GET_RENTAL_COST (state) {
        return state.cost;
    }
}
const mutations = {
    'SET_CARS' (state, cars) {
        state.cars = cars
    },
    'SET_SELECTED_CAR' (state, car) {
        state.selectedCar = car
    },
    'ADD_CAR' (state, car) {
        state.cars.push(car);
    },
    'REMOVE_CAR' (state, id){
        const index = state.cars.findIndex(el => el.id === id)
        state.cars.splice(index, 1);
    },
    'ADD_RENTAL_CONTROL' (state, control){
        state.controls.push(control)
    },
    'REMOVE_RENTAL_CONTROL' (state, id) {
        const index = state.controls.findIndex(el => el.id === id)
        state.controls.splice(index, 1);
    },
    'SET_RENTAL_CONTROLS' (state, controls) {
         state.controls = controls
    },
    'SET_RENTAL_SETUPS' (state, configs) {
        state.configs = configs
    },
    'SET_RENTAL_COST' (state, cost){
        state.cost = cost
    }
}

const actions = {
    getCarDetail({commit, dispatch}, id){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.fetchCarDetail(id).then(data => {
                dispatch("hideLoader")
                if (!data.status) {
                    dispatch('error', {message: data.message})
                    return;
                }
                // commit('SET_SELECTED_CAR', data.extra)
                resolve(data.extra)

            });
        });
    },

    addNewCar({commit, dispatch},formData) {

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.addNewCar(formData).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                // push the returned added car
                commit('ADD_CAR', data.extra)
                dispatch('success', {message: data.message, showSwal: true})
                resolve()

            })
        })
    },

    updateCar({commit, dispatch}, payload) {

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.updateCar(payload).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                // push the returned added car
                dispatch('success', {message: data.message, showSwal: true})
                resolve()

            })
        })
    },

    removeCar({commit, dispatch}, id){
        dispatch('showLoader')
        rentalsService.removeCar(id).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            // push the returned added car
            commit('REMOVE_CAR', id)
            dispatch('success', {message: data.message, showSwal: true})
        })
    },

    getRentalControls({commit, dispatch}){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.fetchControls().then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message})
                    console.log(data.message)
                    reject()
                    return;
                }

                commit('SET_RENTAL_CONTROLS', data.extra)
                resolve(data.extra)

            })
        })
    },

    addRentalControls({commit, dispatch}, formData){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.addControls(formData).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                // push the returned added car
                commit('ADD_RENTAL_CONTROL', data.extra)
                dispatch('success', {message: data.message, showSwal: true})
                resolve()

            })
        })
    },

    removeRentalControl({commit, dispatch}, id){
        dispatch('showLoader')
        rentalsService.removeControl(id).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            // push the returned added car
            commit('REMOVE_RENTAL_CONTROL', id)
            dispatch('success', {message: data.message, showSwal: true})
        })
    },


    updateRentalConfigs({commit, dispatch}, payload){
        dispatch('showLoader')
        rentalsService.updateConfigurations(payload).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            // push the returned added car
            commit('SET_RENTAL_SETUPS', data.extra)
            dispatch('success', {message: data.message, showSwal: true})
        })
    },

    getRentalConfigs({commit, dispatch}){
        dispatch('showLoader')
        rentalsService.getConfigurations().then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            // push the returned added car
            commit('SET_RENTAL_SETUPS', data.extra)
        })
    },

    fetchRentalDetail({ dispatch}, id){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.fetchRentalDetail(id).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    console.log(data.message)
                    reject();
                    return;
                }

                // push the returned added car
                resolve(data.extra)
            })
        })
    },

    calculateRentalCost({commit, dispatch}, payload){
        dispatch('showLoader')
        rentalsService.calculateRentalCost(payload).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                console.log(data.message)
                return;
            }

            // push the returned added car
            commit('SET_RENTAL_COST', data.extra)
        })
    },

    assignCar({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.assignCar(payload).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                dispatch('success', {message: 'Completed', showSwal: true})
                resolve()
            })
        })
    },
    unassignCar({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.unassignCar(payload).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    reject()
                    return;
                }

                dispatch('success', {message: 'Completed', showSwal: true})
                resolve()
            })
        })
    },

    cancelRentRequest({commit, dispatch}, rentalId){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.cancelRentRequest(rentalId).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                // push the returned added car
                commit('SET_SELECTED_CAR', data.extra)
                dispatch('success', {message: 'Completed', showSwal: true})
                resolve()
            })
        })
    },

    completeDepartureControl({commit, dispatch}, payload){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.completeDepartureControl(payload).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                // push the returned added car
                commit('SET_SELECTED_CAR', data.extra)
                dispatch('success', {message: data.message, showSwal: true})
                resolve()
            })
        })
    },

    completeArrivalControl({commit, dispatch}, payload){
        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            rentalsService.completeArrivalControl(payload).then(data => {
                dispatch('hideLoader')
                if (!data.status) {
                    dispatch('error', {message: data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                // push the returned added car
                commit('SET_SELECTED_CAR', data.extra)
                dispatch('success', {message: data.message, showSwal: true})
                resolve()
            })
        })
    },





}

export default {
    state,
    getters,
    actions,
    mutations
}